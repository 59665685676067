import { Goal } from "@/interfaces/goals";
import Vue from "vue";

const loadToStorage = Vue.extend({
  data() {
    return {
      activeStep: 1,
      goals: [],
      accomplishments: [],
      positiveList: "",
      negativeList: "",
      docName: "",
    };
  },
  methods: {
    loadFromLocalStorage() {
      const storedAccomplishments =
        window.localStorage.getItem("accomplishments");
      if (storedAccomplishments && storedAccomplishments.length) {
        this.accomplishments = JSON.parse(storedAccomplishments);
      }

      const storedGoals = window.localStorage.getItem("goals");
      if (storedGoals && storedGoals.length) {
        this.goals = JSON.parse(storedGoals);
      }

      const storedActiveStep = window.localStorage.getItem("activeStep");
      if (storedActiveStep) {
        this.activeStep = JSON.parse(storedActiveStep);
      }

      const storedPositiveList = window.localStorage.getItem("positiveList");
      if (storedPositiveList) {
        this.positiveList = JSON.parse(storedPositiveList);
      }

      const storedNegativeList = window.localStorage.getItem("negativeList");
      if (storedNegativeList) {
        this.negativeList = JSON.parse(storedNegativeList);
      }

      const storedDocName = window.localStorage.getItem("docName");
      if (storedDocName) {
        this.docName = storedDocName;
      }
    },
    clearStorage() {
      window.localStorage.clear();
    },
    storeAccomplishments(accomplishments: string[]) {
      window.localStorage.setItem(
        "accomplishments",
        JSON.stringify(accomplishments)
      );
    },
    storePositives(positiveList: string | string[]) {
      window.localStorage.setItem("positiveList", JSON.stringify(positiveList));
    },
    storeNegatives(negativeList: string | string[]) {
      window.localStorage.setItem("negativeList", JSON.stringify(negativeList));
    },
    storeGoals(goals: Goal[]) {
      window.localStorage.setItem("goals", JSON.stringify(goals));
    },
    storeDocName(docName: string) {
      window.localStorage.setItem("docName", docName);
    },
    storeStep(val: string | number) {
      window.localStorage.setItem("activeStep", JSON.stringify(val));
    },
  },
});

export default loadToStorage;
