

















































import Vue from "vue";

export default Vue.extend({
  name: "StepHelp",
  props: {
    activeStep: Number,
  },
  data() {
    return {
      collapseOpen: false,
      videos: {
        "1": "CO7ybUb1n7o",
        "2": "xJSMvolr3XU",
        "3": "tJ1VQ0Ac0PU",
        "4": "tJ1VQ0Ac0PU",
      },
    };
  },
  watch: {
    activeStep(val) {
      if (val == 4) {
        this.collapseOpen = true;
      }
    },
  },
});
