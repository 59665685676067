





























































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { fireAuth } from "@/firebase";
import { Validator } from "vee-validate";
// used dynamically
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: unreachable code
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import lt from "vee-validate/dist/locale/lt";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: unreachable code
import en from "vee-validate/dist/locale/en";
export default Vue.extend({
  name: "App",
  data() {
    return {
      loginInProgress: true,
      newsletterVisible: true,
    };
  },
  created() {
    fireAuth.getAuth().onAuthStateChanged((state) => {
      this.loginInProgress = false;
      if (!state) {
        return;
      }

      this.setUser({ user: { email: state.email, uid: state.uid } });
      // this.$router.replace("/my-goals");
    });
  },
  methods: {
    ...mapActions({
      setUser: "auth/setUser",
    }),
    switchLanguage(locale: string) {
      this.$root.$i18n.locale = locale;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: unreachable code
      Validator.localize(`${locale}`, [locale]);
    },
  },
  computed: mapGetters({
    user: "auth/getUser",
  }),
});
