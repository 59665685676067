import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueI18n from "vue-i18n";
import translations from "./translations";
import "./filters.ts";

import VeeValidate, { Validator } from "vee-validate";
import lt from "vee-validate/dist/locale/lt";

Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: "veeFields",
  // This is not required but avoids possible naming conflicts
  errorBagName: "veeErrors",
});
Validator.localize("lt", lt);

// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";

import "./firebase.ts";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyC94-Ck75_BidNaSVPUowMwoiemx4ELIxA",
//   authDomain: "goal-planner-45799.firebaseapp.com",
//   projectId: "goal-planner-45799",
//   storageBucket: "goal-planner-45799.appspot.com",
//   messagingSenderId: "541995492095",
//   appId: "1:541995492095:web:f81c9f51b3de2e6379a2ca",
//   measurementId: "G-Q1GGM6CBM6",
// };

// const app = initializeApp(firebaseConfig);
// Vue.prototype.$analytics = getAnalytics(app);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "./main.scss";
// import { mapActions } from "vuex";
// import { fireAuth } from "./firebase";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages: translations,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import "./senderNet.js";
