import Vue from "vue";
import * as firebase from "firebase/app";
import * as auth from "firebase/auth";
import * as firestore from "firebase/firestore";
import { getFirestore } from "firebase/firestore"; // , collection, getDocs
import { getAnalytics } from "firebase/analytics";
// import "firebase/auth";

const firebaseConfig = {
  // databaseURL: "https://maistukas.firebaseio.com",
  apiKey: "AIzaSyC94-Ck75_BidNaSVPUowMwoiemx4ELIxA",
  authDomain: "goal-planner-45799.firebaseapp.com",
  projectId: "goal-planner-45799",
  storageBucket: "goal-planner-45799.appspot.com",
  messagingSenderId: "541995492095",
  appId: "1:541995492095:web:f81c9f51b3de2e6379a2ca",
  measurementId: "G-Q1GGM6CBM6",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

Vue.prototype.$analytics = getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);

// db.settings({ timestampsInSnapshots: true });

const { Timestamp } = firestore;
export { Timestamp };

export const fireAuth = auth; //.getAuth(firebaseApp); //.getAuth(firebaseApp);
