<template>
  <b-container class="py-3">
    <b-row class="d-print-none">
      <b-col class="mb-4">
        <step-indicators :active-step="activeStep" />
      </b-col>
    </b-row>
    <b-row class="py-4 d-print-none">
      <b-col>
        <h1>{{ pageTitle[activeStep] }}</h1>
        <template v-if="activeStep == 2">
          <h4>{{ $t("step.motivation21") }}</h4>
          <p class="mb-0">
            {{ $t("step.motivation22") }}
          </p>
        </template>
        <p v-if="activeStep == 3" class="mb-0">
          {{ $t("step.motivation3") }}
        </p>
      </b-col>
    </b-row>
    <b-row class="d-print-none">
      <b-col>
        <step-help :active-step="activeStep" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-right">
          <b-button
            variant="link"
            @click="showAlreadyAccomplished = !showAlreadyAccomplished"
            class="no-outline-glow"
          >
            <b-icon
              :icon="showAlreadyAccomplished ? 'chevron-up' : 'chevron-down'"
              shift-v="-2"
            ></b-icon>
            {{ $t("btn.yourAccomplishments") }}
          </b-button>
        </div>
        <b-collapse v-model="showAlreadyAccomplished">
          <already-accomplished
            v-model="accomplishments"
            :showHeader="showInfoInAccomplishments"
            @next="accomplishmentsSaved($event)"
          />
        </b-collapse>
      </b-col>
    </b-row>
    <template v-if="activeStep == 1">
      <h4>{{ $t("step.motivation1") }}</h4>
      <p v-html="$t('step.motivation12')"></p>
    </template>
    <template v-if="!accomplishmentsMissing">
      <ul class="list-unstyled w-100">
        <li
          class="mb-1"
          :class="{
            '': !goal.completed,
            'text-muted bg-light': goal.completed,
            'px-2 border-bottom': activeStep == 4,
          }"
          v-for="(goal, index) of goals"
          :key="`goal-${index}`"
        >
          <template v-if="activeStep == 4">
            <b-form-checkbox
              class="my-auto cursor-pointer py-2"
              v-model="goal.completed"
              @change="storeGoals(goals)"
            >
              <div class="d-flex justify-content-between">
                <span>#{{ index + 1 }} {{ goal.text }}</span>
                <year-count v-model="goal.estimate" />
              </div>
            </b-form-checkbox>
          </template>
          <b-card
            v-if="activeStep !== 4"
            :key="`card-${index}`"
            class="mb-2 border-0"
            body-class="py-2 px-4 border-bottom"
            bg-variant="light"
          >
            <b-row :key="index">
              <b-col
                :class="{
                  'col-11': activeStep == 1,
                  'col-9': activeStep == 3,
                  'col-8': activeStep == 2,
                }"
              >
                <b-form-group v-if="activeStep == 1">
                  <label class="mb-0" :for="`goal-${index}`">
                    #{{ index + 1 }}
                    <year-count
                      class="d-inline-block"
                      v-if="activeStep > 2"
                      v-model="goal.estimate"
                    />
                  </label>
                  <b-form-textarea
                    :id="`goal-${index}`"
                    v-model="goal.text"
                    placeholder=""
                    :key="index"
                    @keydown.enter="addGoal()"
                    :disabled="activeStep > 1"
                    @blur="storeGoals(goals)"
                    autocomplete="off"
                    autofocus
                  ></b-form-textarea>
                </b-form-group>
                <p v-else class="mb-0">
                  <span class="small">#{{ index + 1 }}: </span>{{ goal.text }}
                </p>
              </b-col>
              <b-col v-if="activeStep == 2">
                <b-form-group class="mb-0">
                  <b-form-radio-group
                    class="d-flex justify-content-around pt-1"
                    :id="`estimate-${index}`"
                    v-model="goal.estimate"
                    :name="`radios-${index}`"
                  >
                    <b-form-radio
                      class="cursor-pointer"
                      v-for="(opt, index) in estimationOptions"
                      :key="index"
                      :value="opt.value"
                      @change="storeGoals(goals)"
                    >
                      <year-count :value="parseInt(opt.text)" />
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col
                v-if="
                  activeStep == 3 &&
                  goal.estimate == 1 &&
                  (!importantGoalsSelected ||
                    (importantGoalsSelected && goal.important))
                "
                cols="2"
                class="d-flex"
              >
                <b-form-checkbox
                  class="my-auto cursor-pointer"
                  v-model="goal.important"
                  @change="storeGoals(goals)"
                  >{{ $t("btn.important") }}</b-form-checkbox
                >
              </b-col>
              <b-col
                v-if="activeStep == 1"
                cols="1"
                class="d-flex justify-content-center"
              >
                <b-button
                  variant="link"
                  class="my-auto"
                  @click="dropGoal(index)"
                  ><b-icon icon="trash"></b-icon
                ></b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="activeStep > 2 && goal.estimate == 1"
              :key="`motivation-${index}`"
            >
              <b-col cols="12">
                <b-form-group class="mt-2 mb-0" v-if="goal.important">
                  <label class="mb-0" :for="`motivation-${index}`">
                    {{ $t("label.purpose") }}
                  </label>
                  <b-form-textarea
                    :id="`motivation-${index}`"
                    v-model="goal.purpose"
                    placeholder=""
                    :key="index"
                    :disabled="activeStep == 4"
                    autofocus
                    class="pb-0 mb-1"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </li>
      </ul>
      <b-row class="text-center" v-if="activeStep == 1">
        <b-col>
          <b-button class="no-outline-glow" variant="link" @click="addGoal()">{{
            $t("btn.newGoal")
          }}</b-button>
        </b-col>
      </b-row>
    </template>
    <template v-if="activeStep > 2">
      <note-list v-model="positiveList" :title="$t('step.motivation42')" />
      <note-list v-model="negativeList" :title="$t('step.motivation43')" />
    </template>
    <b-row class="mt-3">
      <b-col>
        <b-alert variant="info" :show="nextStepImpossible()">
          <div v-if="accomplishmentsMissing">
            {{ $t("alert.accomplishmentsMissing") }}
          </div>
          <template v-if="!accomplishmentsMissing">
            <div v-if="!goals.length">{{ $t("alert.noGoals") }}</div>
            <div v-if="textMissing">{{ $t("alert.emptyGoalsDetected") }}</div>
            <div v-if="oneYearGoalsMissing && activeStep == 2">
              {{ $t("alert.oneYearGoalsMissing") }}
            </div>
            <div v-if="goalEstimatesMissing && activeStep == 2">
              {{ $t("alert.estimatesMissing") }}
            </div>
            <div v-if="noImportantGoalsSelected && activeStep == 3">
              {{ $t("alert.importantGoalsMissing") }}
            </div>
            <div v-if="importantGoalsPurposeMissing && activeStep == 3">
              {{ $t("alert.importantGoalsPurposeMissing") }}
            </div>
            <div v-if="activeStep == 4">
              {{ $t("alert.celebrateSignificantGoals") }}<br /><br />
              <i>{{ $t("alert.finalQuote") }}</i>
            </div>
          </template>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="!accomplishmentsMissing" class="d-print-none">
      <b-col class="mt-4 border-top pt-3 text-center">
        <b-button
          class="m-1 no-outline-glow"
          variant="link"
          :disabled="activeStep == 1 || !goals.length"
          @click="updateStep(-1)"
          >{{ $t("btn.back") | capitalize }}</b-button
        >
        <b-button
          class="m-1 no-outline-glow"
          variant="link"
          :disabled="nextStepImpossible()"
          v-if="activeStep != 4"
          @click="updateStep(+1)"
          >{{ $t("btn.next") | capitalize }}</b-button
        >
        <b-button
          class="m-1 no-outline-glow"
          variant="link"
          @click="saveInMyGoals()"
        >
          <b-icon icon="cloud-arrow-up"></b-icon>
          {{ $t("btn.saveMyGoals") | capitalize }}
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      v-model="saveModalVisible"
      centered
      title-class="h5"
      title="Enter version name"
      hide-footer
    >
      <div class="d-flex justify-content-between">
        <b-input v-model="docName" class="mr-3"></b-input>
        <b-button @click="syncToCloud(docName)" variant="success">
          <b-icon icon="cloud-arrow-up"></b-icon>
        </b-button>
      </div>
      <p class="small text-muted mt-1 mb-0">
        Tip: Version with the same name will be updated. <br />Use different
        name to create new version.
      </p>
    </b-modal>
  </b-container>
</template>

<script type="ts">
import StepIndicators from "@/components/StepIndicators.vue";
import StepHelp from "@/components/StepHelp.vue";
import AlreadyAccomplished from "@/components/AlreadyAccomplished.vue";
import NoteList from "@/components/NoteList.vue";
import YearCount from "@/components/YearCount.vue";

import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// import { Goal } from "@/interfaces/goals";
import loadToStorage from "@/mixins/loadToStorage";

export default Vue.extend({
  name: "Home",
  components: {
    StepIndicators,
    StepHelp,
    AlreadyAccomplished,
    NoteList,
    YearCount,
  },
  mixins: [
    loadToStorage
  ],
  data() {
    return {
      // activeStep: 1,
      estimationOptions: [
        { text: '1', value: 1},
        { text: '3', value: 3},
        { text: '5', value: 5},
        { text: '10', value: 10},
      ],
      // goals: [],
      // accomplishments: [],
      showAlreadyAccomplished: false,
      showInfoInAccomplishments: false,
      // positiveList: "",
      // negativeList: "",
      saveModalVisible: false,
      // docName: ""
    };
  },
  mounted() {
    this.loadFromLocalStorage();
    // this.addGoal();
    const storedAccomplishments = window.localStorage.getItem("accomplishments");
    if (storedAccomplishments && storedAccomplishments.length) {
      this.accomplishments = JSON.parse(storedAccomplishments);
    } else {
      this.showInfoInAccomplishments = true;
      this.showAlreadyAccomplished = true;
    }

    if (this.accomplishmentsMissing) {
      this.$nextTick(() => {
        this.showAlreadyAccomplished = true;
        this.showInfoInAccomplishments = true;
      });
    }

    // const storedGoals = window.localStorage.getItem("goals");
    // if (storedGoals && storedGoals.length) {
    //   this.goals = JSON.parse(storedGoals);
    // }

    // const storedActiveStep = window.localStorage.getItem("activeStep");
    // if (storedActiveStep) {
    //   this.activeStep = JSON.parse(storedActiveStep);
    // }

    // const storedPositiveList = window.localStorage.getItem("positiveList");
    // if (storedPositiveList) {
    //   this.positiveList = JSON.parse(storedPositiveList);
    // }

    // const storedNegativeList = window.localStorage.getItem("negativeList");
    // if (storedNegativeList) {
    //   this.negativeList = JSON.parse(storedNegativeList);
    // }

    // const storedDocName = window.localStorage.getItem("docName");
    // if (storedDocName) {
    //   this.docName = storedDocName;
    // }
  },
  methods: {
    ...mapActions({
      fetchGoalVersions: "goals/fetchGoalVersions",
      saveVersion: "goals/saveVersion"
    }),
    accomplishmentsSaved(val) {
      this.accomplishments = val;
      if (!this.accomplishmentsMissing) {
        this.showAlreadyAccomplished = false;
        this.showInfoInAccomplishments = false;
      }
    },
    addGoal() {
      this.goals.push({
        text: "",
        completed: false,
        purpose: "",
        estimate: 0,
        dateAdded: new Date().toLocaleString(),
        important: false
      });
      this.storeGoals(this.goals);
    },
    dropGoal(index) {
      this.goals.splice(index, 1);
      this.storeGoals(this.goals);
    },
    sortGoals() {
      this.goals = this.goals.sort((a, b) => a.estimate - b.estimate);
    },
    nextStepImpossible() {
      if (!this.goals.length || this.activeStep == 4) {
        return true;
      }

      if (this.activeStep == 1 && this.textMissing) {
        return true;
      }

      if (this.activeStep == 2 && (this.goalEstimatesMissing || this.oneYearGoalsMissing)) {
        return true;
      }

      if (this.activeStep == 3 && (this.noImportantGoalsSelected || this.importantGoalsPurposeMissing)) {
        return true;
      }

      return false;
    },
    updateStep(val) {
      this.activeStep = this.activeStep + val;
      this.storeStep(this.activeStep);
    },
    saveInMyGoals() {
      this.saveModalVisible = true;
    },
    syncToCloud(docName){
      this.storeDocName(docName);
      this.saveVersion({
        user: this.user.user,
        data: {
          goals: this.goals,
          accomplishments: this.accomplishments,
          negativeList: this.negativeList,
          positiveList: this.positiveList
        },
        docName: docName
      });

      this.saveModalVisible = false;
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
    pageTitle() {
      return {
        1: this.$t("step.header1"),
        2: this.$t("step.header2"),
        3: this.$t("step.header3"),
        4: this.$t("step.header4"),
      };
    },
    accomplishmentsMissing() {
      return this.accomplishments.length !== 5 || this.accomplishments.filter((item) => !item.length).length != 0;
    },
    textMissing() {
      return this.goals.filter(item => item.text == "").length;
    },
    oneYearGoalsMissing() {
      return !this.goals.filter(item => item.text != "" && item.estimate == 1).length;
    },
    goalEstimatesMissing() {
      return this.goals.filter(item => item.text !== "" && item.estimate == 0).length;
    },
    importantGoalsSelected() {
      return this.goals.filter(item => item.text != "" && item.important).length == 4;
    },
    noImportantGoalsSelected() {
      return this.goals.filter(item => item.text != "" && item.important).length == 0;
    },
    importantGoalsPurposeMissing() {
      return this.goals.filter(item => item.text != "" && item.important && item.purpose == "").length != 0;
    }
  },
  watch: {
    activeStep(val) {
      if (val == 2) {
        // filter out empty goals and drop them

      }

      if (val == 3) {
        this.sortGoals();
      }
    },
    accomplishments(val) {
      if (val && val.length && !this.accomplishmentsMissing && !this.goals.length) {
        this.addGoal();
      }
      this.storeAccomplishments(this.accomplishments);
    },
    positiveList(val) {
      if (val) {
        this.storePositives(val);
      }
    },
    negativeList(val) {
      if (val) {
        this.storeNegatives(val);
      }
    },
  }
});
</script>
