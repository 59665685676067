import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { Goal, GoalVersion, SaveGoalPayload } from "@/interfaces/goals";
import { db } from "@/firebase";
import { UserInterface } from "@/interfaces/auth";

function _generateStamp() {
  const d = new Date();
  const m = `${d.getUTCMonth() + 1 < 10 ? 0 : ""}${d.getUTCMonth() + 1}`;
  const day = `${d.getUTCDate() < 10 ? 0 : ""}${d.getUTCDate()}`;
  const stamp = `${d.getUTCFullYear()}-${m}-${day} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}:${d.getMilliseconds()}`;

  return stamp;
}

// state
export const state = {
  goalVersions: [] as GoalVersion[],
  goals: [] as Goal[],
  accomplishments: [] as string[],
  negativeList: [] as string[],
  positiveList: [] as string[],
};

// getters
export const getters = {
  getGoalVersions: (state: { goalVersions: GoalVersion[] }): GoalVersion[] =>
    state.goalVersions,
  getGoals: (state: { goals: Goal[] }): Goal[] => state.goals,
  getAccomplishments: (state: { accomplishments: string[] }): string[] =>
    state.accomplishments,
  getNegativeList: (state: { negativeList: string[] }): string[] =>
    state.negativeList,
  getPositiveList: (state: { positiveList: string[] }): string[] =>
    state.positiveList,
};

// mutations
export const mutations = {
  setGoalVersions(
    state: { goalVersions: GoalVersion[] },
    goalVersions: GoalVersion[]
  ): void {
    state.goalVersions = goalVersions;
  },
  setGoals(state: { goals: Goal[] }, goals: Goal[]): void {
    state.goals = goals;
  },
  setAccomplishments(
    state: { accomplishments: string[] },
    accomplishments: string[]
  ): void {
    state.accomplishments = accomplishments;
  },
  setNegativeList(
    state: { negativeList: string[] },
    negativeList: string[]
  ): void {
    state.negativeList = negativeList;
  },
  setPositiveList(
    state: { positiveList: string[] },
    positiveList: string[]
  ): void {
    state.positiveList = positiveList;
  },
};

// actions
export const actions = {
  setGoalVersions({ commit }: unknown, goalVersions: GoalVersion[]): void {
    commit("setGoalVersions", goalVersions);
  },
  setGoals({ commit }: unknown, goals: Goal[]): void {
    commit("setGoals", goals);
  },
  setAccomplishments(
    { commit }: unknown,
    accomplishments: string[] | []
  ): void {
    commit("setAccomplishments", accomplishments);
  },
  setPositiveList({ commit }: unknown, positiveList: string[] | []): void {
    commit("setPositiveList", positiveList);
  },
  setNegativeList({ commit }: unknown, negativeList: string[] | []): void {
    commit("setNegativeList", negativeList);
  },
  async fetchGoalVersions(
    { commit }: unknown,
    user: UserInterface
  ): Promise<void> {
    const folder = collection(db, "goals", user.uid, "versions");

    const querySnapshot = await getDocs(folder);
    const versionsArray: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      versionsArray.push({ docId: doc.id, ...doc.data() });
    });

    commit("setGoalVersions", versionsArray);
  },
  async saveVersion(
    { dispatch }: unknown,
    payload: SaveGoalPayload
  ): Promise<void> {
    const user = payload.user;
    const data = payload.data;
    const docName = payload.docName;

    // try to find doc by name
    const oldVersion = state.goalVersions.find(
      (item) => item.docName == docName
    );
    if (oldVersion) {
      dispatch("updateGoalsVersion", {
        user: payload.user,
        data: payload.data,
        docId: oldVersion.docId,
        docName: docName,
      });

      return;
    }

    const folder = collection(db, "goals", user.uid, "versions");
    const docRef = doc(folder);

    await setDoc(
      docRef,
      { stamp: _generateStamp(), ...data, docName: docName } || {}
    );
    dispatch("fetchGoalVersions", user, { forRoot: true });
  },
  async updateGoalsVersion({ dispatch }: unknown, payload: any): Promise<void> {
    const user: UserInterface = payload.user;
    const data: GoalVersion = payload.data;
    const docId: string = payload.docId;
    const docName: string = payload.docName;

    const folder = collection(db, "goals", user.uid, "versions");
    // const q = query(folder, where(""));
    const docRef = doc(folder, docId);
    await setDoc(docRef, {
      stamp: _generateStamp(),
      ...data,
      docName: docName,
    });
    dispatch("fetchGoalVersions", user, { forRoot: true });
  },
  async removeGoalVersion({ dispatch }: unknown, payload: any): Promise<void> {
    const user: UserInterface = payload.user;
    const docId: string = payload.docId;

    const folder = collection(db, "goals", user.uid, "versions");
    const docRef = doc(folder, docId);
    await deleteDoc(docRef);
    dispatch("fetchGoalVersions", user, { forRoot: true });
  },
};
