import { UserInterface } from "@/interfaces/auth";

// state
export const state = {
  user: {} as UserInterface | null,
};

// getters
export const getters = {
  getUser: (state: { user: UserInterface | null }): UserInterface | null =>
    state.user,
};

// mutations
export const mutations = {
  setUser(state: { user: UserInterface }, user: UserInterface): void {
    state.user = user;
  },
};

// actions
export const actions = {
  setUser({ commit }: unknown, user: UserInterface | null): void {
    commit("setUser", user);
    if (user && Object.keys(user).length) {
      // dispatch("products/fetchProducts", user, { root: true });
    }
  },
};
