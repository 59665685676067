import { LocaleMessageObject } from "vue-i18n";

const translations: Record<string, LocaleMessageObject> = {
  lt: {
    general: {
      eMail: "el. paštas",
    },
    btn: {
      home: "Pradžia",
      about: "Apie",
      next: "Toliau",
      back: "Atgal",
      newGoal: "Naujas tikslas",
      yourAccomplishments: "Jūsų pasiekimai",
      instructions: "Instrukcijos",
      important: "Svarbus",
      login: "Prisijungti",
      logout: "Atsijungti",
      myGoals: "Mano Tikslai",
      saveMyGoals: "Išsaugoti",
    },
    step: {
      instructions:
        "Šio įrankio atsiradimą įkvėpė Jim Rohn tikslų nustatymo dirbtuvės. Geriausias dalykas, kurį galėtumėte padaryti, yra pažiūrėti trumpą vaizdo įrašą.",
      instructions2:
        "Daugiau informacijos įvedimo laukų rasite <b>kitame</b> formos etape.",
      header1: "Nustatykime tikslus",
      header2: "Įvertinkime kiek tam reikės laiko",
      header3: "Pagrįskime savo tikslus - kodėl tikslai yra tokie svarbūs?",
      header4: "Puiku! Šie metai bus kitokie",
      accomplishmentsHeader: "Pirmiausia - pasidžiaukite savimi",
      accomplishmentsPlaceholder: "Aš didžiuojuosi, kad aš jau ...",
      accomplishments:
        "Kokiais 5-ais dalykais, kuriuos jau pasiekiai, labiausiai didžiuojiesi?",
      motivation1: "Ką norite pasiekti per ateinančius dešimt metų?",
      motivation12:
        "Užsirašykite <b>50</b> dalykų, kuriuos <b>norėtumėte</b> pasiekti per artimiausius dešimt metų. Įsivaizduokite, kad Jūsų niekas nevaržo ir viskas yra įmanoma. Leiskite sau laisvai svajoti. Neskubėkite ir gerai pagalvokite.",
      motivation21: "Kiek metų reikės šiems tikslams pasiekti?",
      motivation22:
        "Įvertinti, kiek metų (1, 3, 5 ar 10) turėtų pakakti pasiekti tikslą.",
      motivation3:
        "Kurie 4 iš Jūsų 1 metų tikslų yra svarbiausi ir turėtų būti įgyvendinami pirmiausia?",
      motivation4:
        "Dabar esate tarp 10% visų žmonių, kurie turi savo aiškius tikslus!",
      motivation41:
        "Dabar, kai turite tikslų sąrašą, turite galimybę stebėti savo pažangą, turite kažką, kas padeda priimti sprendimus dėl to, ką daryti ir ką daryti. Elkitės išmintingai ir dažnai peržiūrėkite šį sąrašą. Šis sąrašas yra saugomas jūsų naršyklėje, bet galima jį atsispausdinti.",
      motivation42: "Kas Jus užveda ir motyvuoja veikti?",
      motivation43: "Kas Jus nuvilia ir demotyvuoja veikti?",
    },
    label: {
      purpose: "Kodėl reikėtų pasiekti šį tikslą? Kas tave motyvuoja?",
      congrats: "Sveikiname!",
    },
    alert: {
      accomplishmentsMissing: "Pagirkite save - Jūs to nusipelnote",
      noGoals: "Pridėti tikslų ir tęskite ...",
      emptyGoalsDetected: "Tušti įrašai negalimi ...",
      oneYearGoalsMissing:
        "Turėtumėte turėti bent kelis tikslų su vienerių metų įvertinimu ...",
      estimatesMissing: "Visi tikslai turėtų būti vertinami ...",
      importantGoalsMissing:
        "Turėtumėte pasirinkti iki 4 svarbių vienerių metų tikslų ...",
      importantGoalsPurposeMissing:
        "Parašykite, kas Jus motyvuoja siekti pačių svarbiausių tikslų ...",
      celebrateSignificantGoals: "Nepamirškite švęsti savo svarbių pasiekimų!",
      finalQuote: '"Add some drama into checking it off..." Jim Rohn',
    },
    login: {
      header: "Prisijunkite",
      password: "Slaptažodis",
      login: "Prisijjungti",
      dontHaveUser: "Dar neturite paskyros? Jūs galite",
      registerHere: "registruotis čia",
    },
    register: {
      header: "Registruotis",
      password: "Slaptažodis",
      register: "Registruotis",
      haveUserAlready: "Jau turite paskyrą? Jūs galite",
      signInHere: "prisijungti čia",
    },
  },
  en: {
    general: {
      eMail: "e-mail",
    },
    btn: {
      home: "Home",
      about: "About",
      next: "Next",
      back: "Back",
      newGoal: "New goal",
      yourAccomplishments: "Your accomplishments",
      instructions: "Instructions",
      important: "Important",
      login: "Sign in",
      logout: "Sign out",
      myGoals: "My Goals",
      saveMyGoals: "Save",
    },
    step: {
      instructions:
        "This tool is inspired by Jim Rohn's goals setting workshop. The best thing You could do is to watch this video about current step.",
      instructions2:
        "Keep on listening this video, but keep in mind, that You will find a place for picking up important goals and make a notes in the <b>next step</b> of this form.",
      header1: "Let's set some goals",
      header2: "Let's estimate your goals",
      header3: "Let's define your purpose",
      header4: "Well done!",
      accomplishmentsHeader: "First - take credit of Yourself",
      accomplishmentsPlaceholder: "I'm proud that I already ...",
      accomplishments:
        "What are 5 things that You already accomplished that You are proud off?",
      motivation1: "What do You want in the next ten years?",
      motivation12:
        "Make a list of <b>50</b> things You would <b>want</b> in next ten years. Imagine that You are not limited by any circumstances and everything is possible. Let Your dreams run free here. Take Your time.",
      motivation21: "How many years it would take to accomplish these goals?",
      motivation22:
        "Estimate how many years (1, 3, 5 or 10) it should take to accomplish the goal.",
      motivation3:
        "Which 4 of Your 1 year goals are most important and should be worked on first?",
      motivation4:
        "You now are in the top 10% of the all people who have their goals well defined!",
      motivation41:
        "Now, when You have a list of goals, You have something to track Your progress on, something who helps with making decisions about what to do and what not to do. Use it wisely and revisit often. This list is stored in Your browser now, refreshing the window won't delete it. But You can allways print it.",
      motivation42: "What's got You turned ON?",
      motivation43: "What's got You turned OFF?",
    },
    label: {
      purpose: "Purpose and motivation",
      congrats: "Congratulations!",
    },
    alert: {
      accomplishmentsMissing: "Don't be shy to say good things about Yourself",
      noGoals: "Add some goals and continue...",
      emptyGoalsDetected: "Empty goals detected...",
      oneYearGoalsMissing:
        "You should have at least some goals with one year estimate...",
      estimatesMissing: "All goals should be estimated...",
      importantGoalsMissing:
        "You should pick up to 4 important one year goals ...",
      importantGoalsPurposeMissing:
        "Write what motivates You to achieve the most important goals ...",
      celebrateSignificantGoals:
        "Don't forget to celebrate Your significant accomplishments!",
      finalQuote: '"Add some drama into checking it off..." Jim Rohn',
    },
    login: {
      header: "Sign in",
      password: "Password",
      login: "Sign in",
      dontHaveUser: "Don't have an account yet? You can",
      registerHere: "register here",
    },
    register: {
      header: "Sign up",
      password: "Password",
      register: "Sign up",
      haveUserAlready: "Already have an account? You can",
      signInHere: "sign in here",
    },
  },
};

export default translations;
