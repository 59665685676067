
































































































import Vue from "vue";

export default Vue.extend({
  name: "StepIndicators",
  props: {
    activeStep: Number,
  },
});
