









import Vue from "vue";
export default Vue.extend({
  name: "YearCount",
  props: {
    value: {
      type: Number,
    },
  },
});
