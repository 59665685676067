

































import Vue from "vue";
export default Vue.extend({
  name: "NoteList",
  props: {
    value: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      notes: "",
      collapseState: false,
    };
  },
  methods: {
    saveNoteList() {
      this.$emit("input", this.notes);
    },
  },
  watch: {
    value(val) {
      if (val.length) {
        this.notes = JSON.parse(JSON.stringify(val));
      }
    },
  },
});
