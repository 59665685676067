





























import Vue from "vue";
export default Vue.extend({
  name: "AlreadyAccomplished",
  props: {
    value: {
      type: Array,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      accomplishments: [] as string[],
    };
  },
  methods: {
    saveAccomplishments() {
      this.$emit("next", this.accomplishments);
    },
  },
  computed: {
    accomplishmentsMissing(): boolean {
      return (
        this.accomplishments.length != 5 ||
        this.accomplishments.filter((item) => item == "").length != 0
      );
    },
  },
  watch: {
    value(val) {
      if (val.length) {
        this.accomplishments = JSON.parse(JSON.stringify(val));
      }
    },
  },
});
